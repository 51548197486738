<template>
  <div class="row">
    <div class="col-md-3">
      <h5>Start Date</h5>
      <b-form-datepicker
        id="example-datepicker"
        v-model="start_date"
        class="mb-1"
        @input="search()"
      />
    </div>
    <div class="col-md-3">
      <h5>End Date</h5>
      <b-form-datepicker
        id="example-datepicker2"
        v-model="end_date"
        class="mb-1"
        @input="search()"
      />
    </div>
    <div class="col-md-2">
      <h5>Choose IP</h5>
      <select class="form-control" v-model="selectedIPIdentifier" @change="getSessions()">
        <option v-for="(val, index) in distinctIps" :value="val" :key="index">
          {{ val }}
        </option>
      </select>
    </div>
    <div class="col-md-3">
      <h5>Choose Session</h5>

      <select
        class="form-control"
        v-model="selectedSession"
        @change="showSessionAsync()"
      >
        <option
          v-for="(val, index) in ipSessions"
          :value="val.session"
          :key="index"
        >
          {{ val.session }} - {{ val.duration }}
        </option>
      </select>
    </div>
    <div id="revolva-viewer"></div>
  </div>
</template>

<script>
import ModelViewer from "revolva";
import { BRow, BCol, BFormDatepicker, BButton } from "bootstrap-vue";

export default {
  name: "AnalyticsStatistics",
  components: {
    BRow,
    BCol,
    BButton,
    BFormDatepicker,
  },
  data: () => ({
    listAnalytics: [],
    listAnalyticsFiltered: [],
    selectedIPIdentifier: null,
    selectedEncryptedIP: null,
    selectedSession: null,
    ipSessions: [],
    distinctIps: [],
    start_date: new Date(Date.now() - 2678400000), // set month ago by default
    end_date: new Date(),
  }),
  computed: {
    projectId() {
      return this.$store.state.Project.project.uuid;
    },
  },
  mounted() {
    console.log('mounted')
    this.getAnalytics();
  },
  created() {
    console.log('created')
  },
  methods: {
    getSessions() {
      const session = {};
      this.listAnalyticsFiltered.forEach((element) => {
        if (element.ip_identifier === this.selectedIPIdentifier) {
          this.selectedEncryptedIP = element.encrypted_ip

          if (session[element.ip_identifier] === undefined) {
            session[element.ip_identifier] = [];
          }
          const duration = this.getSessionTime(
            element.create_date,
            element.update_date
          );
          if (duration != 0) {
            session[element.ip_identifier].push({
              session: element.session,
              duration: duration,
            });
          }
        }
      });
      this.ipSessions = Object.values(session)[0];
    },
    getSessionTime(date1, date2) {
      const dateNow = new Date(date1);
      const dateFuture = new Date(date2);
      let delta = Math.abs(dateFuture - dateNow);
      return this.msToHMS(delta);
    },
    msToHMS(ms) {
      // 1- Convert to seconds:
      let seconds = ms / 1000;
      if (seconds < 5) {
        return 0;
      }
      // 2- Extract hours:
      let hours = parseInt(seconds / 3600); // 3,600 seconds in 1 hour
      seconds = seconds % 3600; // seconds remaining after extracting hours
      // 3- Extract minutes:
      let minutes = parseInt(seconds / 60); // 60 seconds in 1 minute
      // 4- Keep only seconds not extracted to minutes:
      seconds = seconds % 60;
      return `${hours} hours ${minutes.toFixed(0)} minuts ${seconds.toFixed(
        0
      )} seconds`;
    },
    getAnalytics() {
      let projectUuid = this.projectId;

      if (projectUuid) {
        this.$store
          .dispatch("Analytics/getListAnalytics", projectUuid)
          .then((res) => {
            this.listAnalytics = res.data;
            this.getDistinctIp();
          })
          .then(() => {
            this.search()
          })
      }
    },
    getDistinctIp() {
      const IPIdentifiers = {};
      this.listAnalyticsFiltered.forEach((e) => {
        if (IPIdentifiers[e.ip_identifier] === undefined) {
          IPIdentifiers[e.ip_identifier] = [];
        }
        IPIdentifiers[e.ip_identifier].push(e);
      });
      const y = [];
      Object.values(IPIdentifiers).forEach((e) => {
        e.forEach((o) => {
          y.push(o);
        });
      });
      this.distinctIps = Object.keys(IPIdentifiers);
    },
    async showSessionAsync() {
      await (this.initModelviewer())

      // Await controls value
      while(!this.modelViewerInstance.controls)
      await new Promise(resolve => setTimeout(resolve, 50))
      this.modelViewerInstance.enableSimulation(this.selectedSession, this.selectedEncryptedIP)
    },
    search() {
      const list = this.listAnalytics.filter((e) => {
        let create_date = new Date(e.create_date)
        let start_date = new Date(this.start_date)
        let end_date = new Date(this.end_date)

       return create_date >= start_date && create_date <= end_date
      });
      this.listAnalyticsFiltered = list;
      this.getDistinctIp();
    },
    async initModelviewer() {
      const viewer = document.getElementById('revolva-viewer')

      if (viewer.childElementCount > 0) {
        for (let i = 0; i <= viewer.childElementCount; i++) {
          viewer.removeChild(viewer.children[0])
        }
      }
      this.modelViewerInstance = new ModelViewer(
        "revolva-viewer",
        this.projectId,
        'production',
        [], //interaction
        { server: process.env.VUE_APP_SERVER_STATUS, isSimulation: true }
      )
    },
  },
  watch: {
    projectId: function (newVal, oldVal) {
      if (oldVal === undefined) {
        this.getAnalytics();
      }
    },
  },
};
</script>

<style scoped></style>
